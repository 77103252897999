import React from "react";
import PatientView from "../components/ViewPatient";
import { Loader, Message } from "semantic-ui-react";
import { connect } from "react-redux";
import { patientActions } from "../store/actions/patient.actions";
import { strings } from "../resources";
import PropTypes from "prop-types";
import validatePatientInput from "../helpers/patientValidator";
import downloadPdf from "../../Forms/helpers/formToPdfFile";
import formatDateInput from "../../Forms/helpers/FormatDateInput";

import "./css/react-table.css";
import "./css/PatientView.css";

class PatientViewContainer extends React.Component {
  state = {
    loadingPatient: false,
    mode: this.props.location.state ? this.props.location.state.mode : "view",
    confirmSaveModalOpen: false,
    cancelChangesModalOpen: false,
    activationModalOpen: false,
    yesNoChildrenToRemove: [],
    validationErrors: [],
    generatingPdf: false,
  };

  componentDidMount = async () => {
    this.setState({ loadingPatient: true });
    await this.props.getPatientById(
      this.props.match.params.id,
      this.props.headers
    );
    // If patient record is different from previously visited, set the selected tab to 0
    if (
      this.props.selectedTab.patientId !== this.props.match.params.id ||
      !this.props.selectedTab.patientId
    ) {
      this.props.updateSelectedPatientTab(this.props.match.params.id, 0);
    }
    this.setState({ loadingPatient: false });

    if (
      (this.props.selectedPatientData.doctor_signature_name == null ||
        this.props.selectedPatientData.doctor_signature_path == null ||
        this.props.selectedPatientData.doctor_signature_applied_date == null) &&
      this.props.role === "doctor"
    ) {
      this.addDoctorSignatureDetails();
    }
  };

  getPdfFileName = () => {
    const tabNumber = this.props?.selectedTab?.tabNumber;
    if (tabNumber === 0) {
      return "medical-record";
    } else if (tabNumber === 1) {
      return "personal-details";
    } else if (this.props.formType) {
      return this.props.formType;
    } else {
      return "export";
    }
  };

  downloadFormPdf = async () => {
    // Set state (passed to FormEdit to set a loader on download button)
    this.setState({ generatingPdf: true });
    // Download a Pdf of the div with id 'formDiv' and filename formType
    await downloadPdf(document, this.getPdfFileName());
    this.setState({ generatingPdf: false });
  };

  removeFieldError = (field) => {
    this.setState((prevState) => {
      const newErrors = prevState.validationErrors.filter((error) => {
        return error.field !== field;
      });
      return { validationErrors: newErrors };
    });
  };

  handleTabChange = (event, data) => {
    this.props.updateSelectedPatientTab(
      this.props.match.params.id,
      data.activeIndex
    );
  };

  handleActivate = async (event, data) => {
    this.setState({ loadingPatient: true });
    await this.props.activatePatient(
      this.props.match.params.id,
      this.props.headers
    );
    await this.props.getPatientById(
      this.props.match.params.id,
      this.props.headers
    );
    this.setState({ loadingPatient: false, mode: "view" });
  };

  handleDeactivate = async (event, data) => {
    this.setState({ loadingPatient: true });
    await this.props.deactivatePatient(
      this.props.match.params.id,
      this.props.headers
    );
    await this.props.getPatientById(
      this.props.match.params.id,
      this.props.headers
    );
    this.setState({ loadingPatient: false, mode: "view" });
  };

  handleChange = async (event, data) => {
    this.props.updateSelectedPatient(data.name, data.value);
    this.removeFieldError(data.name);
  };

  handleDateChange = async (event, data) => {
    let dataToSubmit = "";
    let currentValue = this.props.selectedPatientData[data.name];
    // Only look to add dashes if there is something to format, and if the user
    // is typing rather than deleting
    if (
      currentValue &&
      data.value.length > 0 &&
      data.value.length > currentValue.length
    ) {
      dataToSubmit = formatDateInput(data.value);
    } else {
      dataToSubmit = data.value;
    }
    await this.props.updateSelectedPatient(data.name, dataToSubmit);
    this.removeFieldError(data.name);
  };

  handleYesNoChange = async (event, data) => {
    // Add the field names of the children to state so they can be removed from data on submission if value of the parent has been changed to clear them, remove if uncleared
    let newFieldsToRemove = this.state.yesNoChildrenToRemove.slice();
    if (data.childrenFields) {
      if (data.clearChild === true) {
        newFieldsToRemove.push(...data.childrenFields);
        // Remove any purged fields from the error reporting array
        data.childrenFields.forEach((childField) => {
          this.props.deleteFromSelectedPatient(childField);
          this.removeFieldError(childField);
        });
      } else {
        newFieldsToRemove = newFieldsToRemove.filter(
          (field) => !data.childrenFields.includes(field)
        );
      }
      this.setState({
        yesNoChildrenToRemove: newFieldsToRemove,
      });
    }
    this.props.updateSelectedPatient(data.name, data.value);
    this.removeFieldError(data.name);
  };

  handleCheckboxChange = (event, value) => {
    this.props.updateSelectedPatient(value.name, value.checked);
    this.removeFieldError(value.name);
  };

  addDoctorSignatureDetails = async () => {
    this.props.updateSelectedPatient(
      "doctor_signature_name",
      `${this.props.givenName} ${this.props.familyName}`
    );
    this.props.updateSelectedPatient(
      "doctor_signature_path",
      this.props.signaturePathOfLoggedInUser
    );
    this.props.updateSelectedPatient(
      "doctor_signature_applied_date",
      new Date().toISOString()
    );
    this.props.updateSelectedPatient("doctor_signature_to_be_updated", true);
    //Also update the patient record with the signature details
    await this.props.applyDoctorSignature(
      this.props.match.params.id,
      this.props.selectedPatientData,
      this.props.headers
    );
  };

  handleCheckboxGroupChange = (event, value) => {
    // If array exists, take a copy, otherwise start a blank array
    let currentGroup = this.props.selectedPatientData.hasOwnProperty(
      value.group
    )
      ? this.props.selectedPatientData[value.group]
      : {};

    //If an "other" value was set in the checkbox, store it and re-apply it
    let otherValue =
      currentGroup[value.name] !== undefined &&
      currentGroup[value.name] !== null &&
      currentGroup[value.name].content !== undefined &&
      currentGroup[value.name].content !== null
        ? currentGroup[value.name].content
        : null;

    // Set the checkbox name in the object to the new checked state
    currentGroup[value.name] = {
      checked: value.checked,
      label: value.label,
    };

    if (otherValue !== null && otherValue !== undefined) {
      currentGroup[value.name].content = otherValue;
    }

    this.props.updateSelectedPatient(value.group, currentGroup);
    this.removeFieldError(value.group);
  };

  handleDateKeyDown = (event) => {
    // Filter the input to the date box - restrict it to numbers, the dash separator,
    // the backspace key (keyCode 8), and the delete key (keyCode 46)
    if (
      isNaN(event.key) &&
      event.key !== "-" &&
      event.keyCode !== 8 &&
      event.keyCode !== 46
    ) {
      event.preventDefault();
    }
  };

  handleNestedChange = async (event, data) => {
    // Only handle the change if the parent group entry is correctly set
    if (this.props.selectedPatientData[data.group].hasOwnProperty(data.child)) {
      let currentGroup = this.props.selectedPatientData[data.group];
      // Set the checkbox name in the object to the new checked state
      currentGroup[data.child].content = data.value;

      this.props.updateSelectedPatient(data.group, currentGroup);
      this.props.updateSelectedPatient(data.name, data.value);
      this.removeFieldError(data.name);
    }
  };

  handleCancel = async () => {
    this.setState({ loadingPatient: true });
    await this.props.getPatientById(
      this.props.match.params.id,
      this.props.headers
    );
    this.setState({
      loadingPatient: false,
      mode: "view",
      validationErrors: [],
    });
  };

  handleSubmit = async () => {
    this.setState({ loadingPatient: true });
    const submitData = Object.assign({}, this.props.pendingPatientUpdate);
    Object.keys(submitData).forEach((field) => {
      if (submitData[field] === "") {
        delete submitData[field];
      }
    });
    this.state.yesNoChildrenToRemove.forEach((field) => {
      submitData[field] = null;
    });
    // If either name is changed, attach a contact name field with both names
    if (
      submitData.hasOwnProperty("first_name") ||
      submitData.hasOwnProperty("surname")
    ) {
      submitData[
        "contact_name"
      ] = `${this.props.selectedPatientData.first_name} ${this.props.selectedPatientData.surname}`;
    }
    // Re-attach data required to make the searchable data field
    submitData["first_name"] = this.props.selectedPatientData.first_name;
    submitData["surname"] = this.props.selectedPatientData.surname;
    submitData["date_of_birth"] = this.props.selectedPatientData.date_of_birth;
    submitData["email_address"] = this.props.selectedPatientData.email_address;
    submitData["phone_number"] = this.props.selectedPatientData.phone_number;
    submitData["postal_address"] =
      this.props.selectedPatientData.postal_address;
    submitData["postcode"] = this.props.selectedPatientData.postcode;
    await this.props.updatePatient(
      this.props.match.params.id,
      submitData,
      this.props.headers
    );
    await this.props.getPatientById(
      this.props.match.params.id,
      this.props.headers
    );
    this.setState({ loadingPatient: false, mode: "view" });
  };

  updateMode = (newMode) => {
    this.setState({ mode: newMode });
  };

  toggleActivationModalVisibility = () => {
    this.setState({
      activationModalOpen: !this.state.activationModalOpen,
    });
  };

  toggleConfirmSaveModalVisibility = async () => {
    const submitData = Object.assign({}, this.props.selectedPatientData);
    Object.keys(submitData).forEach((field) => {
      if (submitData[field] === "") {
        delete submitData[field];
      }
    });
    // Validate the data before showing the modal
    const validationResult = await validatePatientInput(submitData);
    if (validationResult.passedValidation === true) {
      this.setState({
        confirmSaveModalOpen: !this.state.confirmSaveModalOpen,
      });
    } else {
      // If failed validation, don't show modal but show errors and scroll to top
      window.scrollTo(0, 0);
      this.setState({
        validationErrors: validationResult.errors.slice(),
      });
    }
  };

  toggleCancelChangesModalVisibility = () => {
    this.setState({
      cancelChangesModalOpen: !this.state.cancelChangesModalOpen,
    });
  };

  render = () => {
    return (
      <>
        {this.state.loadingPatient ? (
          <Loader active>{strings.header.loading}</Loader>
        ) : (
          <>
            {this.state.validationErrors.length > 0 && (
              <div className="errorMessageContainer">
                <Message compact error>
                  <Message.List>
                    {this.state.validationErrors.some((error) => {
                      return (
                        error.tab ===
                        strings.patientRecord.validation.errorTab.medical
                      );
                    }) && (
                      <>
                        <Message.Header>
                          {strings.patientRecord.edit.label.errorMedicalHeader}
                        </Message.Header>
                        {this.state.validationErrors.map((error) => {
                          return error.tab ===
                            strings.patientRecord.validation.errorTab
                              .medical ? (
                            <Message.Item>{error.error}</Message.Item>
                          ) : (
                            ""
                          );
                        })}
                      </>
                    )}
                  </Message.List>
                  <Message.List>
                    {this.state.validationErrors.some((error) => {
                      return (
                        error.tab ===
                        strings.patientRecord.validation.errorTab.personal
                      );
                    }) && (
                      <>
                        <Message.Header>
                          {strings.patientRecord.edit.label.errorPersonalHeader}
                        </Message.Header>
                        {this.state.validationErrors.map((error) => {
                          return error.tab ===
                            strings.patientRecord.validation.errorTab
                              .personal ? (
                            <Message.Item>{error.error}</Message.Item>
                          ) : (
                            ""
                          );
                        })}
                      </>
                    )}
                  </Message.List>
                </Message>
              </div>
            )}
            <PatientView
              {...this.state}
              updateMode={this.updateMode}
              toggleActivationModalVisibility={
                this.toggleActivationModalVisibility
              }
              toggleConfirmSaveModalVisibility={
                this.toggleConfirmSaveModalVisibility
              }
              toggleCancelChangesModalVisibility={
                this.toggleCancelChangesModalVisibility
              }
              handleSubmit={this.handleSubmit}
              handleCancel={this.handleCancel}
              handleDeactivate={this.handleDeactivate}
              handleActivate={this.handleActivate}
              handleChange={this.handleChange}
              handleTabChange={this.handleTabChange}
              handleDateChange={this.handleDateChange}
              handleYesNoChange={this.handleYesNoChange}
              handleCheckboxChange={this.handleCheckboxChange}
              handleCheckboxGroupChange={this.handleCheckboxGroupChange}
              handleDateKeyDown={this.handleDateKeyDown}
              handleNestedChange={this.handleNestedChange}
              selectedPatientData={this.props.selectedPatientData}
              handleStatusToggle={this.handleStatusToggle}
              headers={this.props.headers}
              history={this.props.history}
              selectedTab={this.props.selectedTab}
              selectedPatientId={this.props.selectedPatientId}
              generatingPdf={this.state.generatingPdf}
              downloadFormPdf={this.downloadFormPdf}
            />
          </>
        )}
      </>
    );
  };
}

PatientViewContainer.propTypes = {
  getPatientById: PropTypes.func.isRequired,
  selectedPatientData: PropTypes.object,
  pendingPatientUpdate: PropTypes.object,
  selectedPatientId: PropTypes.string,
  selectedTab: PropTypes.object,
  deletePatient: PropTypes.func.isRequired,
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  updateSelectedPatient: PropTypes.func.isRequired,
  updatePatient: PropTypes.func.isRequired,
  applyDoctorSignature: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const {
    selectedPatientId,
    loadingTableData,
    selectedPatientData,
    pendingPatientUpdate,
    selectedTab,
  } = state.patients;
  const { signatureObject, annotationsObject } = state.images;
  const { familyName, givenName, signaturePathOfLoggedInUser } = state.auth;
  return {
    selectedPatientId,
    loadingTableData,
    selectedPatientData,
    pendingPatientUpdate,
    selectedTab,
    signatureObject,
    annotationsObject,
    familyName,
    givenName,
    signaturePathOfLoggedInUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPatientById: (id, headers) => {
      return dispatch(patientActions.getPatientById(id, headers));
    },
    deletePatient: (id, headers) => {
      return dispatch(patientActions.deletePatient(id, headers));
    },
    updatePatient: (id, data, headers) => {
      return dispatch(patientActions.updatePatient(id, data, headers));
    },
    activatePatient: (id, headers) => {
      return dispatch(patientActions.activatePatient(id, headers));
    },
    deactivatePatient: (id, headers) => {
      return dispatch(patientActions.deactivatePatient(id, headers));
    },
    applyDoctorSignature: (patientId, details, headers) => {
      dispatch(
        patientActions.applyDoctorSignature(patientId, details, headers)
      );
    },
    updateSelectedPatient: (key, value) => {
      dispatch(patientActions.updateSelectedPatient(key, value));
    },
    deleteFromSelectedPatient: (key, value) => {
      dispatch(patientActions.deleteFromSelectedPatient(key, value));
    },
    updateSelectedPatientTab: (patientId, tabNumber) => {
      dispatch(patientActions.updateSelectedPatientTab(patientId, tabNumber));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PatientViewContainer);
