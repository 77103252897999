const getTodaysDateFormatted = () => {
  let today = new Date();
  let day = today.getDate();
  let month = today.getMonth() + 1;
  let year = today.getFullYear();
  if (day < 10) {
    day = "0" + day;
  }
  if (month < 10) {
    month = "0" + month;
  }
  return day + "-" + month + "-" + year;
};

const getFormattedDateFromISOString = (isoString) => {
  let day = isoString.substring(8, 10);
  let month = isoString.substring(5, 7);
  let year = isoString.substring(0, 4);
  return `${day}-${month}-${year}`;
};

export const dateHelper = {
  getTodaysDateFormatted,
  getFormattedDateFromISOString,
};
