import { strings } from "..";
import { regexes } from "../Regexes";

const emailRegex = new RegExp(regexes.emailRegex);
const passwordRegex = new RegExp(regexes.passwordRegex);

const newUserSchema = {
  role: {
    required: true,
    errorMessagePrefix: strings.form.validation.errorMessagePrefix.role,
  },
  username: {
    required: true,
    errorMessagePrefix: strings.form.validation.errorMessagePrefix.username,
  },
  given_name: {
    required: true,
    errorMessagePrefix: strings.form.validation.errorMessagePrefix.given_name,
  },
  family_name: {
    required: true,
    errorMessagePrefix: strings.form.validation.errorMessagePrefix.family_name,
  },
  email: {
    required: true,
    errorMessagePrefix: strings.form.validation.errorMessagePrefix.email,
    validate: (field, data) => {
      if (emailRegex.test(data[field])) {
        return true;
      } else {
        return strings.form.validation.errorMessageReason.invalidEmailFormat;
      }
    },
  },
  password: {
    required: true,
    errorMessagePrefix: strings.form.validation.errorMessagePrefix.password,
    validate: (field, data) => {
      if (passwordRegex.test(data[field])) {
        return true;
      } else {
        return strings.form.validation.errorMessageReason.invalidPasswordFormat;
      }
    },
  },
};

export default newUserSchema;
