import React from "react";
import ModifyUser from "../../components/ModifyUser";
import { Loader } from "semantic-ui-react";
import { connect } from "react-redux";
import { strings } from "../../resources";
import { userActions } from "../../store/actions/user.actions";
import { authActions } from "../../../App/store/actions/auth.actions";
import validateUserInput from "../../helpers/userValidator";
import PropTypes from "prop-types";

class UserCreateContainer extends React.Component {
  state = {
    confirmSaveModalOpen: false,
    cancelChangesModalOpen: false,
    validationErrors: [],
  };

  componentDidMount = async () => {
    this.props.clearSelectedUser();
  };

  handleSubmit = () => {
    this.props.createUser(this.props.selectedUserData, this.props.headers);
    this.props.history.push("/users");
  };

  handleCancel = () => {
    this.props.history.goBack();
  };

  handleChange = async (event, data) => {
    await this.props.updateSelectedUser(data.name, data.value);
    let signaturePath = `signatures/${this.props.selectedUserData.role}/${this.props.selectedUserData.username}`;
    this.props.updateSelectedUser("signature_path", signaturePath);
    this.removeFieldError(data.name);
  };

  handleModalConfirm = (modalClassName) => {
    switch (modalClassName) {
      case "confirmSaveModal":
        this.handleSubmit();
        break;
      case "cancelChangesModal":
        this.handleCancel();
        break;
      default:
        break;
    }
  };

  removeFieldError = (field) => {
    const newErrors = this.state.validationErrors.filter((error) => {
      return error.field !== field;
    });
    this.setState({
      validationErrors: newErrors,
    });
  };

  toggleConfirmSaveModalVisibility = async () => {
    const submitData = Object.assign({}, this.props.selectedUserData);
    Object.keys(submitData).forEach((field) => {
      if (submitData[field] === "") {
        delete submitData[field];
      }
    });

    // Validate the data before showing the modal
    const validationResult = await validateUserInput(submitData, "newUser");
    if (validationResult.passedValidation === true) {
      this.setState({
        confirmSaveModalOpen: !this.state.confirmSaveModalOpen,
      });
    } else {
      // If failed validation, don't show modal, add errors to state and scroll to top
      window.scrollTo(0, 0);
      this.setState({
        validationErrors: validationResult.errors.slice(),
      });
    }
  };

  toggleCancelChangesModalVisibility = () => {
    this.setState({
      cancelChangesModalOpen: !this.state.cancelChangesModalOpen,
    });
  };

  render = () => {
    return (
      <div>
        <div style={{ display: this.props.loadingPage ? "" : "none" }}>
          <Loader active>{strings.header.loading}</Loader>
        </div>
        <div style={{ display: this.props.loadingPage ? "none" : "" }}>
          <ModifyUser
            mode="create"
            pageTitle={strings.header.createUser}
            selectedUserData={this.props.selectedUserData}
            confirmSaveModalOpen={this.state.confirmSaveModalOpen}
            cancelChangesModalOpen={this.state.cancelChangesModalOpen}
            toggleConfirmSaveModalVisibility={
              this.toggleConfirmSaveModalVisibility
            }
            toggleCancelChangesModalVisibility={
              this.toggleCancelChangesModalVisibility
            }
            handleChange={this.handleChange}
            handleModalConfirm={this.handleModalConfirm}
            validationErrors={this.state.validationErrors}
          />
        </div>
      </div>
    );
  };
}

UserCreateContainer.propTypes = {
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  loadingPage: PropTypes.bool.isRequired,
  selectedUserData: PropTypes.object.isRequired,
  updateSelectedUser: PropTypes.func.isRequired,
  clearSelectedUser: PropTypes.func.isRequired,
  createUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { loadingPage, selectedUserData } = state.users;
  const { loggingIn, isLoggedIn, headers, error } = state.auth;
  return {
    loadingPage,
    selectedUserData,
    loggingIn,
    isLoggedIn,
    headers,
    error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createUser: (data, headers) => {
      //dispatch(userActions.createUser(data, headers));
      dispatch(
        authActions.signUp(
          data.username,
          data.password,
          data.email,
          data.role,
          data.signature_path,
          data.given_name,
          data.family_name
        )
      );
    },
    updateSelectedUser: (key, value) => {
      dispatch(userActions.updateSelectedUser(key, value));
    },
    clearSelectedUser: () => {
      dispatch(userActions.clearSelectedUser());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserCreateContainer);
