import { Auth } from "aws-amplify";
import store from "../_store/store";
import { authActions } from "../App/store/actions/auth.actions";
const { dispatch } = store;
const logOut = () => {
  dispatch(authActions.signOut());
};

// Load interceptors to act on axios function requests and responses before they are sent/received
export default {
  onRequestSuccess: (config) => {
    if (!config.noAuthHeader) {
      return new Promise((resolve) => {
        Auth.currentSession().then((credentials) => {
          const idToken = credentials.getIdToken().getJwtToken();
          if (idToken) {
            let updatedConfig = config;
            updatedConfig["headers"]["Authorization"] = idToken;
            resolve(updatedConfig);
          } else {
            resolve(config);
          }
        });
      });
    } else {
      return Promise.resolve(config);
    }
  },
  onRequestFail: (error) => {
    return Promise.reject(error);
  },
  onResponseSuccess: (response) => {
    return response;
  },
  onResponseFail: (error) => {
    if (error.response && !error.response.status) {
      //If this is a network error, axios won't provide a status code
      return Promise.reject(error);
    }

    if (error.response && error.response.status === 401) {
      logOut();
    }
    return Promise.reject(error);
  },
};
