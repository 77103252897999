import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

const processClone = (clonedDocument) => {
  const checkedCheckboxDivElements = Array.prototype.slice.call(
    clonedDocument.getElementsByClassName("checked checkbox"),
    0
  );
  checkedCheckboxDivElements.forEach((checkboxDiv) => {
    const label = checkboxDiv.getElementsByTagName("LABEL")[0];
    const html2canvasElements = Array.prototype.slice.call(
      label.getElementsByTagName("HTML2CANVASPSEUDOELEMENT"),
      0
    );
    html2canvasElements.forEach((html2canvasElement) => {
      html2canvasElement.remove();
    });
    // Replace the input div with a safari friendly replacement
    checkboxDiv.replaceWith(
      getCheckboxReplacement(clonedDocument, label.innerText)
    );
  });
};

const getCheckboxReplacement = (clonedDocument, label) => {
  // Create a div to replace the entire checkbox div
  const newCheckboxDiv = clonedDocument.createElement("div");

  // Create a new div to represent the checkbox itself
  const newCheckbox = clonedDocument.createElement("div");
  newCheckbox.className = "printToPdfCheckbox";

  // Put a cross inside the checkbox
  const newCheckboxCross = clonedDocument.createElement("span");
  newCheckboxCross.className = "printToPdfCheckboxContent";
  newCheckboxCross.innerHTML = "x";
  newCheckbox.appendChild(newCheckboxCross);

  // Add the checkbox to the div
  newCheckboxDiv.appendChild(newCheckbox);

  // Create a label element and add to the div
  const newCheckboxLabel = clonedDocument.createElement("label");
  newCheckboxLabel.innerHTML = label;
  newCheckboxDiv.appendChild(newCheckboxLabel);

  return newCheckboxDiv;
};

export default (document, fileName = "export") => {
  return new Promise((resolve, reject) => {
    // Fetch the div containing the form
    const formElement = document.getElementById("formDiv");

    // Convert the HTML element to a canvas
    if (formElement) {
      html2canvas(formElement, {
        y: 80, //Truncates the top of the canvas so we don't see the web page header
        useCORS: true,
        onclone: processClone,
      }).then((canvas) => {
        // Convert the canvas to a Jpeg
        const formAsImage = canvas.toDataURL("image/jpeg", 1.0);

        // Convert the Jpeg to a Pdf
        const formAsPdf = new jsPDF({
          orientation: "portrait",
          unit: "px",
          format: [canvas.width + 32, canvas.height + 32],
        });

        formAsPdf.deletePage(1);
        formAsPdf.addPage({ format: [canvas.width + 32, canvas.height + 32] });
        formAsPdf.addImage(
          formAsImage,
          "JPEG",
          16, //x co-ordinate
          16, //y co-ordinate
          formAsPdf.internal.pageSize.getWidth() - 32, //width
          formAsPdf.internal.pageSize.getHeight() - 32 //height
        );
        formAsPdf.save(`${fileName}.pdf`);
        resolve();
      });
    }
  });
};
