import LocalizedStrings from "react-localization";

export const strings = new LocalizedStrings({
  en: {
    header: {
      login: "Login",
      userList: "Users",
      createUser: "Create New User",
      editUser: "Edit User",
      viewUser: "User Details",
      loading: "Loading Information",
    },
    form: {
      header: {
        userInfo: "User Information",
        contactInfo: "Contact Information",
        usersLink: "Users",
        clientsLink: "Clients",
        supportLink: "Support",
        settingsLink: "Settings",
      },
      label: {
        userName: "Username",
        role: "User Role",
        email: "Email",
        firstName: "First Name",
        surname: "Surname",
        password: "Password",
        signaturePath: "Signature Path",
        errorHeader: "There were some errors with your submission, please fix",
      },
      placeholder: {
        username: "Username",
        password: "Password",
        role: "Select User Role",
        signaturePath: "Path to image",
        userName: "Enter Username",
        userType: "Enter User Type",
        firstName: "Enter First Name",
        surname: "Enter Surname",
        email: "Enter Email Address",
        contactEmailAddress: "Enter Contact Email Address",
        contactNumber: "Enter Contact Phone Number",
        searchBar: "Search",
      },
      validation: {
        errorMessagePrefix: {
          role: "User Role",
          username: "User Name",
          given_name: "First Name",
          family_name: "Surname",
          email: "Email Address",
          password: "Password",
        },
        errorMessageReason: {
          invalidEmailFormat: "must be a valid email address format.",
          invalidPasswordFormat:
            "must be a valid password format with at least 8 characters, including a capital letter, small letter and number.",
        },
      },
    },
    modal: {
      createUserMessage: "Save user?",
      modifyUserMessage: "Are you sure you want to save these changes?",
      deleteUserMessage:
        "Are you sure you want to delete this user? (This action cannot be undone)",
      saveChangesMessage: "Are you sure you want to save these changes?",
      cancelChangesMessage: "Cancel changes?",
      setActiveMessage: "Set this user's status to active?",
      setInactiveMessage: "Set this user's status to inactive?",
    },
    button: {
      loginLabel: "Login",
      editLabel: "Edit",
      deleteLabel: "Delete",
      cancelLabel: "Cancel",
      saveLabel: "Save",
      yesLabel: "Yes",
      noLabel: "No",
      okLabel: "Ok",
      addUserLabel: "Add User",
    },
    tooltip: {
      editUser: "Edit User",
      deleteUser: "Delete User",
      toggleStatus: "Click to change status",
      userTypeWarning: "User Type cannot be changed after creation.",
    },
    table: {
      header: {
        userName: "Username",
        userType: "User Type",
        firstName: "First Name",
        surname: "Surname",
        email: "Email",
        createdDate: "Added",
        status: "Active",
        actions: "Actions",
        ViewIcon: "View Details",
      },
    },
  },
});
